import React from "react"
import PropTypes from 'prop-types'

const MusarteLogo = ({ width }) => (
    <div>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 871.32 431.71" width={width}>
            <g id="Layer_2" data-name="Layer 2">
                <g id="Final">
                    <path
                        d="M746.48,45.13a66,66,0,0,1-14.09-4.86C653.22-22.47,597.25,6.58,446.86,7.56c-3.22,0-5.85,0-7.89,0s-4.69,0-7.91,0c-150.37-1-216.62-27.87-289.52,32.71a71.39,71.39,0,0,1-14.83,4.86c-14.15,2.75-35,8.61-35,8.61v33.4S110.38,86,115.1,97.74s8.82,60.19,22.1,88.79c17.87,38.51,47.2,69.77,98.65,76.2,56.68,7.09,98.3-6.71,130.51-37,39.08-36.71,41.78-89.72,36.83-135.1,0,0,12.63-6.28,33.85-6.28s33.83,6.28,33.83,6.28c-4.95,45.38-2.28,98.39,36.81,135.1,32.21,30.25,73.75,44,130.43,37,51.45-6.43,80.64-37.69,98.51-76.2,13.28-28.6,16.76-77,21.47-88.79s22.46-10.6,22.46-10.6V53.74S760.62,47.88,746.48,45.13ZM371,195C354.16,222.46,315,250.12,257.7,248.8S173,223.06,155.52,190.19c-18.39-34.68-25.74-88.31-12.67-116s56.23-47.92,118.41-47.52,98.25,29.28,110.89,49.1C397.88,116.14,389,165.44,371,195Zm66.1-129c-16.5,0-35.95,3-40.27,4.91,0,0-15.33-35.75-73.47-50.28-28.52-7.14-54-9.46-76.54-8.75C294,4.44,356.84,14.15,431,14.63c3.25,0,5.93,0,8,0s4.74,0,8,0C521.2,14.15,580.1,4.44,627.34,11.8c-22.51-.71-48,1.61-76.55,8.75-58.14,14.53-73.46,50.28-73.46,50.28C473,68.87,453.56,65.92,437.06,65.92ZM718.59,190.19c-17.42,32.87-44.85,57.29-102.17,58.61S520,222.46,503.16,195c-18-29.51-26.93-78.81-1.19-119.21C514.6,55.92,550.68,27,612.85,26.64S718.2,46.44,731.26,74.16,737,155.51,718.59,190.19Z"
                        style={{ fill: `var(--color-primary)` }}
                    />
                    <path
                        d="M91.72,414.33V304.76c0-12.35,8.74-23.36,22-23.36H125l31,92.67,31.14-92.67h33.61V414.33H195v-82l-20.7,59.82c-2.47,7.41-4.37,16.33,1.52,20.51a27.12,27.12,0,0,1-12.73,2.85c-14.43,0-21.64-10.63-25.82-23.36l-19.94-59.82v82Z"
                        style={{ fill: `var(--color-primary)` }}
                    />
                    <path
                        d="M229,361V335.71c0-12.34,6.65-19.94,19.56-19.94,1.71,0,3.42.19,5.32.38v47.1c0,14.24.19,30,18,30s18.61-14.81,18.61-29.24V316.15H315v41.4c.38,30.57-6.45,58.49-43.1,58.49S229,390.4,229,361Z"
                        style={{ fill: `var(--color-primary)` }}
                    />
                    <path
                        d="M323.41,399.33,338.61,383c6.83,8,14.62,11,20.51,11,6.26,0,10.63-3,10.63-7.22,0-14.05-42.73-16-42.73-45,0-17.47,15.19-27.92,32.48-27.92,11.77,0,26,4.75,26,17.85A15.69,15.69,0,0,1,376,346.35c-2.85-7.22-10.64-10.26-16.52-10.26-3.61,0-9.12,1.14-9.12,6.08,0,12.53,42.35,11.39,42.35,43.68,0,19.36-16.71,30.38-34.75,30.38A43.11,43.11,0,0,1,323.41,399.33Z"
                        style={{ fill: `var(--color-primary)` }}
                    />
                    <path
                        d="M401.08,327.93a14,14,0,1,1,27.92,0,14,14,0,1,1-27.92,0Zm14.06,59.81a14.06,14.06,0,1,1-14.06,14.06A13.87,13.87,0,0,1,415.14,387.74Z"
                        style={{ fill: `var(--color-primary)` }}
                    />
                    <path
                        d="M483.31,313.49c16.71,0,27.35,8.17,27.54,23a14.53,14.53,0,0,1-1.71,7.6c-4.18-6.27-13.3-8-19.56-8-18.23,0-27.54,15-27.54,29.82,0,14.24,8.55,28.29,26,28.29,20.7,0,26.39-20.13,26.39-38V316h24.69v98.37H514.45V403.51a43.94,43.94,0,0,1-31.33,13.29c-23.36,0-45.77-19-45.77-50.51C437.35,335,459.57,313.49,483.31,313.49Z"
                        style={{ fill: `var(--color-pink)` }}
                    />
                    <path
                        d="M547.5,316.15H572v8c3.8-6.84,9.5-10.83,17.47-10.83,19.94,0,25.26,22,14.44,30-1.71-4.56-6.27-6.84-11.4-6.84a20.25,20.25,0,0,0-20.32,20.7v57.16H547.5Z"
                        style={{ fill: `var(--color-pink)` }}
                    />
                    <path
                        d="M617.75,316.15h12.16v-5.31c0-12.16,9.87-19.56,20.89-19.56a12.7,12.7,0,0,1,3.79.19v24.68h22c0,9.88-3,20.51-16.71,20.51h-5.32v50.52c0,3.79,3.23,6.45,7,6.45,4.37,0,9.12-1.14,11-4.18a18.25,18.25,0,0,1,1.13,6.08c0,10.44-8.16,19.75-22.21,19.75-10.64,0-21.65-7-21.65-21.27V336.66H617.75Z"
                        style={{ fill: `var(--color-pink)` }}
                    />
                    <path
                        d="M738.34,314.44c24.88,0,41.21,13.3,41.21,32.48,0,19.37-16.33,33.8-38,33.8-16.14,0-27-8-27-19.94a21.93,21.93,0,0,1,1.71-8.36c6.08,5.32,13.48,8.17,19.75,8.17,12.92,0,22-4.56,22-12.53,0-5.13-5.32-10.45-17.85-10.45-19.18,0-30.57,10.07-30.57,27.35,0,16.9,11,27.53,26.39,27.53,8,0,16.71-1.14,23-7.21l14.24,16.33c-9.11,9.87-21.27,14.81-36.08,14.81C706.44,416.42,685,395,685,365.34,685,335,707.39,314.44,738.34,314.44Z"
                        style={{ fill: `var(--color-pink)` }}
                    />
                    <path
                        d="M14.4,337.71c12.72-3.8,12-20.51,12-31.34,0-31.14,4.75-39.88,37.6-39.88H75c.38,10.07-3,20.7-15.2,20.7-11.39,0-12,8.55-12,18.23V324c0,11.4-5.32,18.8-15.19,25.07,9.87,6.46,15.19,13.86,15.19,25.45v18.8c0,8.73.57,17.66,11.2,17.66h2.47c11.21,0,13.87,12,13.49,20.7H64c-32.66,0-37.6-9.12-37.6-41.78,0-18.23-1.52-30.39-18.8-30.39H.92C-1.55,352.9.35,341.69,14.4,337.71Z"
                        style={{ fill: `var(--color-secondary)` }}
                    />
                    <path
                        d="M811.48,411c11.39,0,12-8.55,12-18.23V374.17c0-11.4,5.32-18.8,15.2-25.26-9.88-6.27-15.2-13.67-15.2-25.26v-18.8c0-8.73-.57-17.66-11.2-17.66h-2.47c-11,0-13.67-12-13.48-20.7h11c32.66,0,37.6,9.12,37.6,41.78,0,18.23,1.52,30.39,18.8,30.39h6.65c2.66,6.64.38,17.85-13.48,21.83-12.73,3.61-12,20.51-12,31.34,0,31.14-4.75,39.88-37.6,39.88h-11C795.91,421.64,799.33,411,811.48,411Z"
                        style={{ fill: `var(--color-secondary)` }}
                    />
                </g>
            </g>
        </svg>
    </div>
)

MusarteLogo.propTypes = {
    width: PropTypes.number.isRequired,
}

export default MusarteLogo
